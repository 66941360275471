import { NgFor } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { SanitizeHtmlPipe } from '../../shared/sanitizeHtml.pipe';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [NgFor, SanitizeHtmlPipe, TranslocoModule],
  template: `
    <section id="faq" class="py-16 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2
          class="text-3xl font-bold text-center mb-12"
          *transloco="let t; read: 'faq'"
        >
          {{ t('title') }}
        </h2>
        <div class="space-y-6">
          <div *ngFor="let faq of faqs">
            <h3 class="text-xl font-semibold mb-2">
              {{ faq.question | transloco }}
            </h3>
            <p [innerHTML]="faq.answer | transloco | sanitizeHtml"></p>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit {
  faqs: { question: string; answer: string }[] = [];
  private translocoService = inject(TranslocoService);

  ngOnInit(): void {
    const faqs = [
      {
        question: 'faq.question.create-new-menu',
        answer: 'faq.answer.create-new-menu',
      },
      {
        question: 'faq.question.upload-multiple-images',
        answer: 'faq.answer.upload-multiple-images',
      },
      {
        question: 'faq.question.enable-disable-items',
        answer: 'faq.answer.enable-disable-items',
      },
      {
        question: 'faq.question.schedule-menu-visibility',
        answer: 'faq.answer.schedule-menu-visibility',
      },
      {
        question: 'faq.question.automatic-translation',
        answer: 'faq.answer.automatic-translation',
      },
      {
        question: 'faq.question.place-orders',
        answer: 'faq.answer.place-orders',
      },
      {
        question: 'faq.question.pay-orders',
        answer: 'faq.answer.pay-orders',
      },
      {
        question: 'faq.question.menu-analytics',
        answer: 'faq.answer.menu-analytics',
      },
      {
        question: 'faq.question.augmented-reality',
        answer: 'faq.answer.augmented-reality',
      },
      {
        question: 'faq.question.easy-setup',
        answer: 'faq.answer.easy-setup',
      },
      {
        question: 'faq.question.customize-design',
        answer: 'faq.answer.customize-design',
      },
      {
        question: 'faq.question.suitable-for-businesses',
        answer: 'faq.answer.suitable-for-businesses',
      },
      {
        question: 'faq.question.update-offerings',
        answer: 'faq.answer.update-offerings',
      },
      {
        question: 'faq.question.support',
        answer: 'faq.answer.support',
      },
      {
        question: 'faq.question.future-features',
        answer: 'faq.answer.future-features',
      },
    ];

    this.faqs = faqs;
  }
}
