import { AsyncPipe, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule, TranslocoService, translate } from '@jsverse/transloco';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'app-features',
  standalone: true,
  imports: [NgFor, TranslocoModule, AsyncPipe],
  template: `
    <section id="features" class="py-16 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold text-center mb-12" *transloco="let t; read: 'features'">{{ t('title') }}</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div class="bg-white p-6 rounded-lg shadow-md" *ngFor="let feature of features$ | async">
            <h3 class="text-xl font-semibold mb-4">{{feature.title}}</h3>
            <p>{{feature.subtitle}}</p>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesComponent {
  private translocoService = inject(TranslocoService);
  features$!: Observable<{ title: string; subtitle: string; }[]>;

  ngOnInit(): void {
    const featureKeys = [
      'instant-qr-menu-access',
      'showcase-products-with-multiple-images',
      'real-time-menu-management',
      'scheduled-menu-visibility',
      'multilingual-support',
      'seamless-online-ordering',
      'seamless-payment-options',
      'menu-analytics',
      'effortless-setup',
      'enhanced-customer-experience',
      'customizable-menu-design',
      'scalable-for-any-business',
      'always-up-to-date',
      'stay-ahead-with-future-features'
    ];

    const randomFeatureKeys = featureKeys.sort(() => Math.random() - 0.5).slice(0, 3);

    this.features$ = this.translocoService.selectTranslateObject('features').pipe(
      map(translations => randomFeatureKeys.map(key => ({
        title: translations[key],
        subtitle: translations[`${key}-subtitle`]
      })))
    );
  }

}
