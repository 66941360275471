import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [TranslocoModule],
  template: `
    <section *transloco="let t; read: 'hero'">
      <div class="px-8 py-24 mx-auto md:px-12 lg:px-32 max-w-7xl">
        <div class="text-center">
          <h1
            class="text-4xl font-semibold tracking-tighter text-gray-900 lg:text-5xl text-balance"
          >
            {{ t('title') }}
            <span class="text-gray-600">Carta Ya</span>
          </h1>
          <p class="mt-4 text-base font-medium text-gray-500">
            {{ t('description') }}
          </p>
          <div
            class="flex flex-col items-center justify-center gap-2 mx-auto mt-8 md:flex-row"
          >
            <button
              class="inline-flex items-center justify-center w-full h-12 px-6 py-3 text-base font-medium text-white transition duration-200 bg-indigo-600 md:w-auto rounded-xl hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              aria-label="Register"
              type="button"
              (click)="goToRegister()"
            >
              {{ t('button') }}
            </button>
          </div>

          <div class="grid mx-auto mt-12 sm:grid-cols-3 lg:mt-24">
            <img
              class="hidden mx-auto sm:block lg:-mr-24"
              src="/images/cafeteria.png"
              alt=""
            /><img
              class="hidden mx-auto sm:block lg:-mt-12 lg:z-50"
              src="/images/food-truck.png"
              alt=""
            /><img
              class="mx-auto lg:-ml-24"
              src="/images/manhattan.png"
              alt=""
            />
          </div>
        </div>

        <!-- https://windstatic.com/headers -->
      </div>
    </section>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeroComponent {
  router = inject(Router);
  goToRegister() {
    this.router.navigate(['/register']);
  }
}
