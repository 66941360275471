import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-demo',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  template: `
    <section class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto" *transloco="let t; read: 'demo'">
      <div class="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
        <div>
          <img class="rounded-xl" src="https://res.cloudinary.com/dbsyo8whb/image/upload/v1724711260/canvas-image_pn3suj.jpg" alt="Demo de la aplicación Carta Ya">
        </div>
        <div class="mt-5 sm:mt-10 lg:mt-0">
          <div class="space-y-6 sm:space-y-8">
            <div class="space-y-2 md:space-y-4">
              <h2 class="font-bold text-3xl align-left">
                {{ t('title') }}
              </h2>
              <p class="text-gray-500 dark:text-neutral-500">
                {{ t('description') }}
              </p>
            </div>
            <ul class="space-y-2 sm:space-y-4">
              <li class="flex gap-x-3">
                <span class="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full bg-indigo-50 text-indigo-600 dark:bg-indigo-800/30 dark:text-indigo-500">
                  <svg class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
                </span>
                <span class="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                  <span class="font-bold">{{ t('quick-menu-creation') }}</span>
                </span>
              </li>
              <li class="flex gap-x-3">
                <span class="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full bg-indigo-50 text-indigo-600 dark:bg-indigo-800/30 dark:text-indigo-500">
                  <svg class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
                </span>
                <span class="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                  <span class="font-bold">{{ t('real-time-menu-management') }}</span>
                </span>
              </li>
              <li class="flex gap-x-3">
                <span class="mt-0.5 w-5 h-5 flex justify-center items-center rounded-full bg-indigo-50 text-indigo-600 dark:bg-indigo-800/30 dark:text-indigo-500">
                  <svg class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>
                </span>
                <span class="text-sm sm:text-base text-gray-500 dark:text-neutral-500">
                  <span class="font-bold">{{ t('showcase-products-with-multiple-images') }}</span>
                </span>
              </li>
            </ul>
            <div class="mt-6">
              <a href="/menu/bar-cito" class="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white transition duration-200 bg-indigo-600 rounded-xl hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ t('try-our-demo') }}
                <svg class="w-4 h-4 ml-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: [`
    :host {
      display: block;
    }
  `]
})
export class DemoComponent {}