import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { TranslocoService, getBrowserLang } from '@jsverse/transloco';
import { distinctUntilChanged, filter, interval, map, startWith, Subject, switchMap, take, tap } from 'rxjs';
import { SettingsService } from './services/settings.service';
import { StoreService } from './services/store.service';
import { AlertComponent } from './shared/alert/alert.component';
import { CannyService } from './services/canny.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AlertComponent, AsyncPipe, JsonPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private storeService = inject(StoreService);
  private titleService = inject(Title);
  private translateService = inject(TranslocoService);
  private cannyService = inject(CannyService);

  state$ = this.storeService.store$.pipe(
    tap(state => this.titleService.setTitle(state.appTitle)),
  );
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    const browserLang = getBrowserLang() || (navigator as any).userLanguage;
    const langPrefix = browserLang.split('-')[0];

    const availableLangs = this.translateService.getAvailableLangs() as string[];
    const matchedLang = availableLangs.find(lang => lang.startsWith(langPrefix)) || 'en';

    this.translateService.setActiveLang(matchedLang);
    this.translateService.load(matchedLang).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();

    this.initCanny();
  }

  async initCanny() {
    await this.cannyService.loadCannyScript();
    this.cannyService.initCanny();
  }



}
