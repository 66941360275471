import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-cta',
  standalone: true,
  imports: [TranslocoModule],
  template: `
    <section class="py-16 bg-indigo-600 text-white" *transloco="let t; read: 'cta'">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl font-bold mb-4">{{t('title')}}</h2>
        <p class="text-xl mb-8">{{t('subtitle')}}</p>
        <a href="#" class="bg-white text-indigo-600 font-bold py-3 px-8 rounded-full hover:bg-indigo-100 transition duration-300">{{t('button')}}</a>
      </div>
    </section>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtaComponent {}
