import { Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AuthGuard } from './guards/auth.guard';
import { CartaComponent } from './components/carta/carta.component';
import { HomeComponent } from './components/home/home.component';

export const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: 'login', loadComponent: () => import('./components/login/login.component').then(m => m.LoginComponent) },
            { path: 'register', loadComponent: () => import('./components/register/register.component').then(m => m.RegisterComponent) },
            { path: 'recover', loadComponent: () => import('./components/recover/recover.component').then(m => m.RecoverComponent) },
            {
                path: 'settings',
                loadComponent: () => import('./components/settings/settings.component').then(m => m.SettingsComponent),
                canActivate: [AuthGuard]
            },
            {
                path: 'dashboard',
                loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent),
                canActivate: [AuthGuard],
                children: [
                    {
                        path: 'menus',
                        loadComponent: () => import('./components/menus/menus.component').then(m => m.MenusComponent),
                    },
                    { path: 'menus/new', loadComponent: () => import('./components/menu-form/menu-form.component').then(m => m.MenuFormComponent) },
                    { path: 'menus/:id', loadComponent: () => import('./components/menu-form/menu-form.component').then(m => m.MenuFormComponent) },
                    {
                        path: 'menus/:id/products',
                        loadComponent: () => import('./components/menu-products/menu-products.component').then(m => m.MenuProductsComponent)
                    },
                    {
                        path: 'products',
                        loadComponent: () => import('./components/products/products.component').then(m => m.ProductsComponent),
                    },
                    { path: 'products/new', loadComponent: () => import('./components/product-form/product-form.component').then(m => m.ProductFormComponent) },
                    { path: 'products/:id', loadComponent: () => import('./components/product-form/product-form.component').then(m => m.ProductFormComponent) },
                    { path: '', redirectTo: 'menus', pathMatch: 'full' }
                ]
            },
            {
                path: 'thankyou',
                loadComponent: () => import('./components/thankyou/thankyou.component').then(m => m.ThankyouComponent)
            },
            { path: 'menu/:publicName', component: CartaComponent },
            { path: '', redirectTo: '/login', pathMatch: 'full' }
        ]
    }
];
