import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.interface';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.authService.getToken();
        const { apiUrl } = environment as Environment;
        const publicEndpoints: string[] = ['/images', '/auth'];

        // Check if the request is going to your API and not to other domains
        if (token && request.url.startsWith(apiUrl)) {
            // Allow GET requests to /images without token
            if (request.method === 'GET' && publicEndpoints.some(endpoint => request.url.includes(endpoint))) {
                return next.handle(request);
            }

            // For all other requests, add the authorization header
            const authReq = request.clone({
                setHeaders: { Authorization: `Bearer ${token}` }
            });
            return next.handle(authReq).pipe(
                tap({
                    next: (event) => {
                        if (event instanceof HttpResponse && event.status === 401) {
                            this.authService.logout();
                            this.router.navigate(['/login']);
                        }
                    },
                    error: (event) => {
                        if (event.status === 401) {
                            this.authService.logout();
                            this.router.navigate(['/login']);
                        }
                    }
                })
            );
        }

        // If there's no token or the request is not for your API, let it pass through unchanged
        return next.handle(request);
    }
}

// To configure which endpoints should have the token and which ones not:
// 1. You can modify the condition in the if statement above to include or exclude specific endpoints.
// 2. For more granular control, you can create an array of protected endpoints and check against it:
//
// const protectedEndpoints = ['/protected', '/user', '/data'];
// if (token && protectedEndpoints.some(endpoint => request.url.includes(endpoint))) {
//   // Add token to request
// }
//
// 3. Alternatively, you can create an array of public endpoints and exclude those:
//
// const publicEndpoints = ['/login', '/register', '/public'];
// if (token && !publicEndpoints.some(endpoint => request.url.includes(endpoint))) {
//   // Add token to request
// }