import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from './header-component';
import { HeroComponent } from './hero-component';
import { SocialProofComponent } from './social-proof-component';
import { FeaturesComponent } from './features-component';
import { TestimonialsComponent } from './testimonials-component';
import { FaqComponent } from './faq-component';
import { CtaComponent } from './cta-component';
import { FounderNoteComponent } from './founder-note-component';
import { FooterComponent } from './footer-component';
import { DemoComponent } from './demo.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    HeroComponent,
    SocialProofComponent,
    FeaturesComponent,
    TestimonialsComponent,
    FaqComponent,
    CtaComponent,
    FounderNoteComponent,
    FooterComponent,
    DemoComponent
  ],
  template: `
    <app-hero></app-hero>
    <!-- <app-social-proof></app-social-proof> -->
    <app-features></app-features>
    <app-demo></app-demo>
    <!-- <app-testimonials></app-testimonials> -->
    <app-faq></app-faq>
    <app-cta></app-cta>
    <!-- <app-founder-note></app-founder-note> -->
    <app-footer></app-footer>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent {

}
