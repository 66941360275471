import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { SanitizeHtmlPipe } from '../../shared/sanitizeHtml.pipe';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslocoModule, SanitizeHtmlPipe, NgIf, RouterModule],
  template: `
    <footer class="bg-gray-800 text-white py-8" *transloco="let t; read: 'footer'">
  <div class="container mx-auto px-4">
    <div class="flex flex-wrap justify-between">
      <div class="w-full md:w-1/4 px-1 mb-6 md:mb-0">
        <img src="/assets/transparentHeaderSmall.png" alt="Carta Ya Logo" class="h-8 mb-4">
        <p>{{ t('empowering') }}</p>
      </div>
      <div class="w-full md:w-1/4 px-1 mb-6 md:mb-0">
        <h3 class="text-lg font-semibold mb-4">{{ t('quickLinks') }}</h3>
        <ul class="space-y-2">
          <li><a href="#" class="hover:text-indigo-300">{{ t('home') }}</a></li>
          <li><a href="#features" class="hover:text-indigo-300">{{ t('features') }}</a></li>
          <!-- <li><a href="#testimonials" class="hover:text-indigo-300">{{ t('testimonials') }}</a></li> -->
          <li><a href="#faq" class="hover:text-indigo-300">{{ t('faq') }}</a></li>
          <li><a routerLink="thankyou" href="" class="hover:text-indigo-300">{{ t('thankyou') }}</a></li>
        </ul>
      </div>
      <div class="w-full md:w-1/4 px-1 mb-6 md:mb-0">
        <h3 class="text-lg font-semibold mb-4">{{ t('contactUs') }}</h3>
        <p>{{ t('email') }}: info&#64;cartaya.app</p>
        <!-- <p>{{ t('phone') }}: (123) 456-7890</p> -->
      </div>
      <div class="w-full md:w-1/4 px-1">
        <h3 class="text-lg font-semibold mb-4">{{ t('followUs') }}</h3>
        <div class="flex space-x-4" *ngIf="socialLinks">
          <a *ngIf="socialLinks.facebook" href="{{ socialLinks.facebook }}" class="hover:text-indigo-300" target="_blank">{{ t('facebook') }}</a>
          <a *ngIf="socialLinks.twitter" href="{{ socialLinks.twitter }}" class="hover:text-indigo-300" target="_blank">{{ t('twitter') }}</a>
          <a *ngIf="socialLinks.instagram" href="{{ socialLinks.instagram }}" class="hover:text-indigo-300" target="_blank">{{ t('instagram') }}</a>
        </div>
      </div>
    </div>
    <div class="mt-8 text-center">
      <p>{{ t('copyright') }}</p>
    </div>
  </div>
</footer>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  socialLinks = environment.socialLinks;
}
