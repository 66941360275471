import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { switchMap, map, startWith } from 'rxjs/operators';

export interface AlertConfig {
  title?: string;
  description?: string;
  duration: number; // duration in milliseconds
  alertType?: 'info' | 'warning' | 'error' | 'success';
}

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [NgIf, AsyncPipe, NgClass],
  template: `
    <div *ngIf="visible$ | async" role="alert" [ngClass]="getAlertClass((config$ | async)?.alertType)">
      <div class="flex items-start gap-4">
        <span [ngClass]="getIconClass((config$ | async)?.alertType)">
          <svg
            *ngIf="(config$ | async)?.alertType"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              [attr.d]="getAlertPath((config$ | async)?.alertType)"
            />
          </svg>
        </span>

        <div class="flex-1">
          <strong class="block font-medium text-gray-900">{{ (config$ | async)?.title }}</strong>
          <p class="mt-1 text-sm text-gray-700">{{ (config$ | async)?.description }}</p>
        </div>

        <button (click)="dismiss()" class="text-gray-500 transition hover:text-gray-600">
          <span class="sr-only">Dismiss popup</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        position: fixed;
        right: 10px;
        top: 20px;
        z-index: 1;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
  private configSubject = new BehaviorSubject<AlertConfig | null>(null);
  config$: Observable<AlertConfig | null> = this.configSubject.asObservable();
  visible$: Observable<boolean> = of(false);

  @Input() set alertConfig(config: AlertConfig) {
    this.configSubject.next(config);
  }

  ngOnInit() {
    this.visible$ = this.config$.pipe(
      switchMap(config => {
        if (config) {
          return timer(0, config.duration).pipe(
            map(tick => tick === 0),
            startWith(true)
          );
        }
        return of(false);
      })
    );
  }

  dismiss() {
    this.configSubject.next(null);
  }

  getAlertClass(alertType: string | undefined): string {
    const baseClasses = 'rounded-xl border p-4';
    switch (alertType) {
      case 'success':
        return `${baseClasses} border-green-100 bg-green-50`;
      case 'info':
        return `${baseClasses} border-blue-100 bg-blue-50`;
      case 'warning':
        return `${baseClasses} border-yellow-100 bg-yellow-50`;
      case 'error':
        return `${baseClasses} border-red-100 bg-red-50`;
      default:
        return `${baseClasses} border-gray-100 bg-white`;
    }
  }

  getIconClass(alertType: string | undefined): string {
    switch (alertType) {
      case 'success':
        return 'text-green-600';
      case 'info':
        return 'text-blue-600';
      case 'warning':
        return 'text-yellow-600';
      case 'error':
        return 'text-red-600';
      default:
        return 'text-gray-600';
    }
  }

  getAlertPath(alertType: string | undefined): string {
    switch (alertType) {
      case 'success':
        return 'M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z';
      case 'info':
        return 'M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z';
      case 'warning':
        return 'M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z';
      case 'error':
        return 'M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z';
      default:
        return '';
    }
  }
}
